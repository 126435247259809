import React, { useReducer, useState, useEffect } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import axios from 'axios';
import '../assets/styles/pages/quote/step-six.scss';
import Layout from '../components/layout';
import SEO from '../components/seo';

/* global window */
const ContactUs = () => {
  const data = useStaticQuery(graphql`
    query {
      general {
        email
      }
      contactus {
        customertype {
          customerType_id
          description
        },
        recaptcha {
          siteKey
        }
        seo {
          title
          canonical
          description
          og {
            description
            image
            title
            type
            url
          }
        }
      }
    }
  `);

  const { seo, customertype, recaptcha } = data.contactus;
  const { email } = data.general;
  const [errorMessage, setErrorMessage] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [captcha, setCaptcha] = useState(null);
  const [contactDetails, setContactDetails] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      name: '',
      email: '',
      enquiry: '',
      type: '',
    }
  );
  const [contactDetailsValid, setContactDetailsValid] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      name: true,
      email: true,
      enquiry: true,
      type: true,
    }
  );

  const addScript = (url) => {
    const script = window.document.createElement('script');
    script.src = url;
    script.async = true;
    window.document.body.appendChild(script);
  };

  if (typeof window !== 'undefined') {
    window.ReCaptchaCallbackV3 = () => {
      grecaptcha.ready(() => {
        setCaptcha(grecaptcha);
      });
    };

    if (typeof window.grecaptcha !== 'object') {
      addScript(`https://www.google.com/recaptcha/api.js?onload=ReCaptchaCallbackV3&render=${recaptcha.siteKey}`);
    }
  }

  useEffect(() => {
    // If you want to implement componentWillUnmount,
    // return a function from here, and React will call
    // it prior to unmounting.
    return () => {
      if (typeof window.grecaptcha === 'object') {
        window.grecaptcha = undefined;
      }
    }
  }, []);

  const validateForm = (name, value) => {
    const re = /\S+@\S+\.\S+/;

    if (name === 'name') {
      if (value.length === 0) {
        setContactDetailsValid({ 'name': false });
      } else {
        setContactDetailsValid({ 'name': true });
      }
    }
    if (name === 'email') {
      if (value.length === 0 || !re.test(value)) {
        setContactDetailsValid({ 'email': false });
      } else {
        setContactDetailsValid({ 'email': true });
      }
    }
    if (name === 'enquiry') {
      if (value.length === 0) {
        setContactDetailsValid({ 'enquiry': false });
      } else {
        setContactDetailsValid({ 'enquiry': true });
      }
    }
    if (name === 'type') {
      if (value.length === 0) {
        setContactDetailsValid({ 'type': false });
      } else {
        setContactDetailsValid({ 'type': true });
      }
    }
  };

  const handleInputChange = (event) => {
    let { name, value } = event.target;
    if (event.target.type === 'checkbox') {
      value = event.target.checked;
    }

    setContactDetails({ [name]: value });
    validateForm(name, value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    setSuccessMessage(false);
    setErrorMessage(false);

    if (captcha !== null) {
      grecaptcha.execute(recaptcha.siteKey, { action: 'contactus' }).then((token) => {
        contactDetails.token = token;
        axios.post(`${process.env.GATSBY_PORT}endpoints/contactform`,
          contactDetails,
        ).then(res => {
          const { data } = res;
          if (data.success) {
            setSuccessMessage(true);
            setLoading(false);
          } else {
            setErrorMessage(true);
            setLoading(false);
          }
        }).catch(error => {
          console.warn(error);
          setErrorMessage(true);
          setLoading(false);
        });
      });
    } else {
      console.warn('Google recaptcha failed to load');
      setErrorMessage(true);
      setLoading(false);
    }
  };

  const hasContactDetails = () => {
    let valid = true;
    const re = /\S+@\S+\.\S+/;

    if (contactDetails.name.length === 0) {
      valid = false;
    }
    if (contactDetails.email.length === 0) {
      valid = false;
    }
    if (!re.test(contactDetails.email)) {
      valid = false;
    }
    if (contactDetails.type.length === 0) {
      valid = false;
    }
    if (contactDetails.enquiry.length === 0) {
      valid = false;
    }

    return valid;
  }

  return (
    <Layout hideHeaderFooter={true}>
      <SEO
        title={seo.title}
        description={seo.title}
        canonical={seo.canonical}
        stucturedData=""
        ogTitle={seo.og.title}
        ogDescription={seo.og.description}
        ogImage={seo.og.image}
        ogType={seo.og.type}
        ogUrl={seo.og.url}
      />
      <div className="quote-wrapper">
        <div className="container xs">
          <h1>We're here to help</h1>
          <p className="intro">
            Have a question? Fill out the form below to contact our team and we will get back to you.<br />If you are looking for a quote for the repair of your vehicle please <Link to="/quote">visit here</Link>.<br />Please note we <strong>cannot provide quotes without photos</strong>. We are not a panel beater we work with panel beaters in your local area to get you quotes for repairs using photos of your damaged vehicle.<br />If you are looking for more information about repairs you can read more in our <a href="/blog">blog here</a>.
          </p>
          <form onSubmit={(e) => { handleSubmit(e); }}>
            <input type="hidden" name="recaptcha_key" value={recaptcha.siteKey} />
            <div className="radio-btn-wrapper">
              {customertype.map((type) => (
                <div key={type.customerType_id} className={`radio-container ${contactDetailsValid.type ? 'input-valid' : 'input-error'}`}>
                  <label className="radio-label">
                    {type.description}
                    <input type="radio" onChange={(e) => { handleInputChange(e); }} value={type.customerType_id} name="type" />
                    <span className="checkmark"></span>
                  </label>
                </div>
              ))}
            </div>
            <div className={`form-group ${contactDetailsValid.name ? 'input-valid' : 'input-error'}`}>
              <label>Your name</label>
              <input type="text" className="form-control" id="name" name="name" onChange={(e) => { handleInputChange(e); }} value={contactDetails.name} />
            </div>
            <div className={`form-group ${contactDetailsValid.email ? 'input-valid' : 'input-error'}`}>
              <label>Email address</label>
              <input type="email" className="form-control" id="email" name="email" onChange={(e) => { handleInputChange(e); }} value={contactDetails.email} />
            </div>
            <div className={`form-group ${contactDetailsValid.enquiry ? 'input-valid' : 'input-error'}`}>
              <label>Enquiry</label>
              <textarea
                id="enquiry"
                name="enquiry"
                onChange={(e) => { handleInputChange(e); }}
              />
            </div>
            {errorMessage && (
              <p className="error">
                Hi Sorry we are experiencing a problem submitting your request. Can you please message us on our online chat or email us your issue at <a href={`mailto:${email}`}>{email}</a> and we will look to resolve your case asap. Thank you!
              </p>
            )}
            {successMessage && (
              <p className="success">
                Thanks for your enquiry, we will be in contact soon
              </p>
            )}
            <div className="form-btn-wrapper">
              <button className={`btn ${hasContactDetails() ? '' : 'disable'}`} type="submit">
                {loading && (
                  <div className="lds-ellipsis">
                    <div />
                    <div />
                    <div />
                    <div />
                  </div>
                )}
                {!loading && 'Submit enquiry'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </Layout>
  );
};

export default ContactUs;
